<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <!-- <section class="header-table">
            <v-col class="d-flex" cols="12" sm="12">
                <v-text-field
                    style="margin-right: 10px;"
                    class="rounded-lg"
                    v-model="profileName" 
                    label="Nome do Perfil"
                    single-line
                    hide-details
                    outlined
                    dense
                ></v-text-field>
                <v-btn class="rounded-lg" 
                    elevation="1" 
                    color="green" 
                    @click="saveProfile()">
                    <span class="textBtn" style="color: white;"> Salvar Novo Perfil</span>
                </v-btn>
            </v-col>
        </section> -->
            
        <template>
            <v-data-table
            :disabled="disableTable"
            :headers="headers"
            :items="abilities"
            item-value="id"
            :selectable="true"
            :single-select="false"
            v-model="selectedItems"
            show-select
            class="elevation-1"
            >
            <template v-slot:top>
                <v-toolbar flat>
                <v-toolbar-title>Lista de Habilidades: {{ profileName }}</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-checkbox v-model="selectedItems" :value="item"></v-checkbox>
            </template>
            </v-data-table>
        </template>
        <br>
        <v-row>
            <v-btn color="success" @click="saveAbilities()" style="margin-right: 10px; margin-left: 10px">Salvar</v-btn>
            <v-btn color="white" @click="$router.push('/permissoes')">Sair</v-btn>
        </v-row>
      </v-card>

      <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import axios from "@/axios";
  export default {
    data() {
      return {
        headers: [
          { text: 'Habilidades', align: 'start', sortable: false, value: 'name' },
          { text: 'Slug', value: 'slug' },
        ],
        items: [
          { name: 'Item 1', description: 'Descrição do Item 1' },
          { name: 'Item 2', description: 'Descrição do Item 2' },
          { name: 'Item 3', description: 'Descrição do Item 3' },
          // Adicione mais itens conforme necessário
        ],
        profileName:"",
        selectedItems: [],
        selectedItemsId: [],
        abilities: [],
        snackbar: false,
        message: "",
        disableTable: true
      };
    },

    created(){
        this.profileName = this.$route.query.item.name
        this.loadAbilities()
    },

    methods: {
        loadAbilities(){
            axios.get("/abilities").then((response) => {
                this.abilities = response.data.data
            });
        },

        saveAbilities(){
            for(let i = 0; i < this.selectedItems.length; i++){
                this.selectedItemsId.push(this.selectedItems[i].id)
            }
        
            let id = this.$route.query.item.id
            let abilities = new Object
                abilities.abilities = this.selectedItemsId

            axios.post(`/profiles/${id}/abilities`, abilities).then((response) => {
                this.message = response.data.message;
                this.snackbar = true;
                this.$router.push({path:'/permissoes'})
            });
        }
    }
  };
  </script>
  